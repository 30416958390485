import React, { Component, useContext, useEffect, useLayoutEffect, useState } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { UserContext } from "../../../context/User";
import moment from "moment";
import { imageDB } from "../../../utility/imageData";

import { AroundRow, BetweenRow, FlexstartRow, Row } from "../../../common/Row";
import { Column, FlexstartColumn } from "../../../common/Column";

import Button from "../../../common/Button";
import { DataContext } from "../../../context/Data";

import { readuserbydeviceid, Update_attendancebyusersid } from "../../../service/UserService";
import { getDateEx, getDateFullTime } from "../../../utility/date";
import { useDispatch } from "react-redux";
import { ALLREFRESH } from "../../../store/menu/MenuSlice";
import { shuffleArray, sleep } from "../../../utility/common";
import LottieAnimation from "../../../common/LottieAnimation";

import ButtonEx from "../../../common/ButtonEx";
import {motion} from 'framer-motion';
import RotateCardBasic from "../../../common/RotateCardBasic";
import RotateCard from "../../../common/RotateCard";

const Container = styled.div`
  padding-top:10px;

`
const style = {
  display: "flex"
};

const SubText = styled.div`
  font-family: 'Pretendard-SemiBold';
  font-size: 20px;
  color :#131313;
  margin-top:10px;
`

const GameItems = [
  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  {image:imageDB.game11,open:false,index:11},
  {image:imageDB.game12,open:false,index:12},

  {image:imageDB.game1,open:false,index:1},
  {image:imageDB.game2,open:false,index:2},
  {image:imageDB.game3,open:false,index:3},
  {image:imageDB.game4,open:false,index:4},
  {image:imageDB.game5,open:false,index:5},
  {image:imageDB.game6,open:false,index:6},
  {image:imageDB.game7,open:false,index:7},
  {image:imageDB.game8,open:false,index:8},
  {image:imageDB.game9,open:false,index:9},
  {image:imageDB.game10,open:false,index:10},
  {image:imageDB.game11,open:false,index:11},
  {image:imageDB.game12,open:false,index:12},



]

const ButtonSt = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 80px;
    color :#fff;
    font-family:Pretendard-SemiBold;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;

    

`
const Time = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  font-size: 16px;
  font-family: 'Pretendard-Bold';

`
const MobileGame =({containerStyle}) =>  {

  const [seconds, setSeconds] = useState(0); // 10초 카운트다운

  

  const reduxdispatch = useDispatch();

  const { dispatch, user } = useContext(UserContext);
  const { datadispatch, data} = useContext(DataContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);

  const [items, setItems] = useState(shuffleArray(GameItems));

  const [initialize, setInitialize] = useState(true);
  const [isRunning, setIsRunning] = useState(false);
  const [enableclickcount, setEnableclickcount] = useState(60);
  const [alert, setAlert] = useState(false);

  useLayoutEffect(() => {
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);




  useEffect(() => {
    let timer = null;
    if (isRunning) {
      data.gameitems = [];
      datadispatch(data);

      // 타이머 실행
      timer = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    } else {
      // 타이머 정지
      clearInterval(timer);
    }
    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearInterval(timer);
  }, [isRunning]);

  useEffect(()=>{
    setItems(items);
    setInitialize(initialize);
    setSeconds(seconds);
    setEnableclickcount(enableclickcount);
    setAlert(alert);

  },[refresh])

  const onPress = (success) =>{

    if(enableclickcount == 0){
      _handleStop();
      return;
    }
    let count = enableclickcount -1;
    setEnableclickcount(count);

    if(success){
      setAlert(true);
    }else{
      setAlert(false)
    }
    setRefresh((refresh) => refresh+1);
  }

  const _handleStart =() =>{
    setInitialize(false);
    setIsRunning(true);
    setRefresh((refresh) => refresh +1);
  }
  const _handleStop =() =>{
    setIsRunning(false);
    setInitialize(true);
    setRefresh((refresh) => refresh +1);  
    setSeconds(0);
    setEnableclickcount(60);
    data.gameitems = [];
    datadispatch(data);
  }

  // 분과 초로 변환
  const minutes = Math.floor(seconds / 60); // 총 초를 60으로 나눈 몫이 분
  const remainingSeconds = seconds % 60; // 총 초를 60으로 나눈 나머지가 초


  return (
    <Container style={containerStyle}>
        <Row margin={'0px auto;'} width={'98%'} style={{background:"#fff", flexWrap:"wrap"}} >

        {
          initialize == true ? (  <>
            {
              items.map((data, index)=>(
                <RotateCardBasic image={data.image} open={data.open} index = {index}/>
              ))
            }
            </>) :(  <>
            {
              items.map((data, index)=>(
                <RotateCard image={data.image}  item = {data} index={index} callback={onPress}/>
              ))
          
            }

            <div style={{position:"absolute"}}>
              {
                alert == true &&   <motion.div animate={{ scale: [1, 3, 0] }} transition={{ duration: 1 }}>
                <div  style={{fontFamily:"Pretendard-SemiBold", color:"#ff7e19", fontSize:25}}>{'일치'}</div>
              </motion.div>
              }
            
          
            </div>


            </>)
        }
        
        
        {
          initialize == true && <ButtonSt onClick={_handleStart}>
            <img src={imageDB.gamestart} style={{width:70, borderRadisu:"70px"}}/>
          </ButtonSt>
        }

        {
          initialize == false &&  <BetweenRow style={{width:"90%", margin:"0 auto"}}>
          <div style={{width:"30%"}}> 
            {
              initialize == false && <ButtonEx text={'게임중지'} width={'100'}  
              onPress={_handleStop} bgcolor={'#FF7E19'} color={'#FFF'} 
              containerStyle={{fontFamily:"Pretendard-SemiBold", boxShadow:"none", height:34, fontSize:16,marginTop:10}} />
            }
            
          </div>
          <BetweenRow style={{width:"70%", fontSize:16, padding:"10px 0px 0px 50px", color : '#F75100', fontFamily:"Pretendard-SemiBold"}}> 
            {
              enableclickcount < 60 && <motion.div animate={{ scale: [1, 3, 0] }} transition={{ duration: 1, repeat: Infinity, repeatType: 'loop' }}>
              <div >{enableclickcount}</div>
            </motion.div>

            }  
              
            <Time>경과시간</Time>
            <div>{minutes} : {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</div>
          </BetweenRow>
          </BetweenRow>
        }


                 
     
     
        </Row>
    </Container>
  );

}

export default MobileGame;

