import { ROOMSIZE } from "./room";
import { WORKNAME } from "./work";

export const imageDB = {
  download: require("../assets/imageset/download.png"),
  pclogo: require("../assets/imageset/pclogo.png"),
  logogif: require("../assets/imageset/logogif.gif"),
  logo2: require("../assets/imageset/logo2.png"),
  init : require("../assets/imageset/init.png"),
  enablecheck: require("../assets/imageset/enablecheck.png"),
  disablecheck: require("../assets/imageset/disablecheck.png"),
  search: require("../assets/imageset/search.png"),
  infocircle: require("../assets/imageset/info-circle.png"),
  redsearch: require("../assets/imageset/redsearch.png"),
  logout: require("../assets/imageset/logout.png"),
  user: require("../assets/imageset/user.png"),
  pcslpash: require("../assets/imageset/PCSplash.png"),
  mobileslpash: require("../assets/imageset/MobileSplash.png"),
  Empty : require("../assets/imageset/Empty.png"),
  tour: require("../assets/imageset/tour.gif"),
  tourcountry: require("../assets/imageset/tourcountry.gif"),
  festival : require("../assets/imageset/festival.png"),
  medical: require("../assets/imageset/medical.png"),
  food: require("../assets/imageset/food.png"),
  convenience: require("../assets/imageset/convenience.png"),
  board: require("../assets/imageset/board.webp"),
  performance: require("../assets/imageset/performance.png"),
  expand: require("../assets/imageset/expand.png"),
  success: require("../assets/imageset/success.png"),
  warning: require("../assets/imageset/warning.png"),
  fail: require("../assets/imageset/fail.png"),
  dot : require("../assets/imageset/dot.png"),
  heartoff: require("../assets/imageset/heartoff.png"),
  hearton: require("../assets/imageset/hearton.png"),
  uploadimage :  require("../assets/imageset/uploadimage.png"),
  profile :  require("../assets/imageset/profile.png"),
  introduce1 :  require("../assets/imageset/introduce1.mp4"),
  introduce2 :  require("../assets/imageset/introduce2.mp4"),
  introduce3 :  require("../assets/imageset/introduce3.mp4"),
  introduce4 :  require("../assets/imageset/introduce4.mp4"),
  introduce5 :  require("../assets/imageset/introduce5.mp4"),
  person : require("../assets/imageset/person.png"),
  logo: require("../assets/imageset/logo.webp"),
  logo2: require("../assets/imageset/logo2.png"),
  honglogo : require("../assets/imageset/honglogo.png"),
  uploadenable : require("../assets/imageset/uploadenable.png"),
  uploaddisable : require("../assets/imageset/uploaddisable.png"),
  mobilebanner1 :require("../assets/imageset/mobilebanner1.webp"),
  mobilebanner2 :require("../assets/imageset/mobilebanner2.webp"),
  mobilebanner3 :require("../assets/imageset/mobilebanner3.webp"),
  mobilebanner4 :require("../assets/imageset/mobilebanner4.webp"),
  mobilebanner5 :require("../assets/imageset/mobilebanner5.webp"),
  mobilebanner6 :require("../assets/imageset/mobilebanner6.webp"),
  mobilebanner7 :require("../assets/imageset/mobilebanner7.webp"),
  mobilebanner8 :require("../assets/imageset/mobilebanner8.png"),
  workbannerex1 :require("../assets/imageset/workbannerex1.webp"),
  workbannerex2 :require("../assets/imageset/workbannerex2.webp"),
  workbannerex3 :require("../assets/imageset/workbannerex3.webp"),
  workbannerex4 :require("../assets/imageset/workbannerex4.webp"),
  workbannerex5 :require("../assets/imageset/workbannerex5.webp"),
  roombannerex1 :require("../assets/imageset/roombannerex1.webp"),
  roombannerex2 :require("../assets/imageset/roombannerex2.webp"),
  roombannerex3 :require("../assets/imageset/roombannerex3.webp"),
  roombannerex4 :require("../assets/imageset/roombannerex4.webp"),
  roomplus :require("../assets/imageset/roomplus.png"),
  movegps :require("../assets/imageset/movegps.png"),
  movegpsex :require("../assets/imageset/movegps.gif"),
  check_d:require("../assets/imageset/check_d.png"),
  check_e:require("../assets/imageset/check_e.png"),
  checkbtn_d:require("../assets/imageset/checkbtn_d.png"),
  checkbtn_e:require("../assets/imageset/checkbtn_e.png"),
  enablecheck:require("../assets/imageset/enablecheck.png"),
  filter:require("../assets/imageset/filter.png"),
  filterblack:require("../assets/imageset/filterblack.png"),
  reset:require("../assets/imageset/reset.png"),
  memo:require("../assets/imageset/memo.png"),
  enablememo:require("../assets/imageset/enablememo.png"),
  Up:require("../assets/imageset/Up.png"),
  drug :require("../assets/imageset/drug.png"),
  healthfood :require("../assets/imageset/healthfood.png"),
  camping :require("../assets/imageset/camping.png"),
  loading :require("../assets/imageset/loading.json"),
  loadinglarge :require("../assets/imageset/loadinglarge.json"),
  pinmove :require("../assets/imageset/pin_h.json"),
  gps :require("../assets/imageset/movegps.png"),
  memo2 :require("../assets/imageset/memo2.png"),
  selection_d :require("../assets/imageset/selection_d.png"),
  selection_e :require("../assets/imageset/selection_e.png"),


  roomsize1: require("../assets/imageset/roomsize1.webp"),
  roomsize2: require("../assets/imageset/roomsize2.webp"),
  roomsize3: require("../assets/imageset/roomsize3.png"),
  roomsize4: require("../assets/imageset/roomsize4.webp"),
  roomsize5: require("../assets/imageset/roomsize5.png"),




  sample12: require("../assets/imageset/sample12.webp"),
  sample10: require("../assets/imageset/sample10.png"),
  sample31: require("../assets/imageset/sample31.gif"),
  sample32: require("../assets/imageset/sample32.gif"),
  sample33: require("../assets/imageset/sample33.png"),


  license: require("../assets/imageset/license.png"),



  guide1: require("../assets/imageset/guide1.webp"),
  guide2: require("../assets/imageset/guide2.webp"),
  guide3: require("../assets/imageset/guide3.webp"),
  guide4: require("../assets/imageset/guide4.webp"),
  guide5: require("../assets/imageset/guide5.webp"),
  guide6: require("../assets/imageset/guide6.webp"),
  guide7: require("../assets/imageset/guide7.webp"),
  guide8: require("../assets/imageset/guide8.webp"),
  guide9: require("../assets/imageset/guide9.webp"),
  guide10: require("../assets/imageset/guide10.webp"),
  guide11: require("../assets/imageset/guide11.webp"),
  guide12: require("../assets/imageset/guide12.webp"),
  guide13: require("../assets/imageset/guide13.webp"),
  guide14: require("../assets/imageset/guide14.webp"),
  guide15: require("../assets/imageset/guide15.webp"),
  guide16: require("../assets/imageset/guide16.webp"),
  guide17: require("../assets/imageset/guide17.webp"),
  guide18: require("../assets/imageset/guide18.webp"),
  guide19: require("../assets/imageset/guide19.webp"),
  guide20: require("../assets/imageset/guide20.webp"),
  guide21: require("../assets/imageset/guide21.webp"),
  guide22: require("../assets/imageset/guide22.webp"),
  guide23: require("../assets/imageset/guide23.webp"),
  guide24: require("../assets/imageset/guide24.webp"),



  room1: require("../assets/imageset/room1.webp"),
  room2: require("../assets/imageset/room2.webp"),
  room3: require("../assets/imageset/room3.webp"),
  room4: require("../assets/imageset/room4.webp"),
  room5: require("../assets/imageset/room5.webp"),
  room6: require("../assets/imageset/room6.webp"),
  room7: require("../assets/imageset/room7.webp"),
  room8: require("../assets/imageset/room8.webp"),
  room9: require("../assets/imageset/room9.webp"),
  room10: require("../assets/imageset/room10.webp"),
  room11: require("../assets/imageset/room11.webp"),
  room12: require("../assets/imageset/room12.webp"),


  community1: require("../assets/imageset/community1.webp"),
  community2: require("../assets/imageset/community2.webp"),
  community3: require("../assets/imageset/community3.webp"),
  community4: require("../assets/imageset/community4.webp"),
  community5: require("../assets/imageset/community5.webp"),
  community6: require("../assets/imageset/community6.webp"),
  community7: require("../assets/imageset/community7.webp"),
  community8: require("../assets/imageset/community8.webp"),
  community9: require("../assets/imageset/community9.webp"),
  community10: require("../assets/imageset/community10.webp"),

  transaction1: require("../assets/imageset/transaction1.webp"),
  transaction2: require("../assets/imageset/transaction2.webp"),
  transaction3: require("../assets/imageset/transaction3.webp"),
  transaction4: require("../assets/imageset/transaction4.webp"),
  transaction5: require("../assets/imageset/transaction5.webp"),
  transaction6: require("../assets/imageset/transaction6.webp"),
  transaction7: require("../assets/imageset/transaction7.webp"),
  transaction8: require("../assets/imageset/transaction8.webp"),
  transaction9: require("../assets/imageset/transaction9.webp"),
  transaction10: require("../assets/imageset/transaction10.webp"),
  transaction11: require("../assets/imageset/transaction11.webp"),
  transaction12: require("../assets/imageset/transaction12.webp"),


  contact1: require("../assets/imageset/contact1.webp"),
  contact2: require("../assets/imageset/contact2.webp"),
  contact3: require("../assets/imageset/contact3.webp"),
  contact4: require("../assets/imageset/contact4.webp"),
  contact5: require("../assets/imageset/contact5.webp"),
  contact6: require("../assets/imageset/contact6.webp"),
  contact7: require("../assets/imageset/contact7.webp"),


  close: require("../assets/imageset/close.png"),
  close2: require("../assets/imageset/close2.png"),
  bell: require("../assets/imageset/bell.png"),
  hongmoveicon: require("../assets/imageset/hongmove.png"),
  hongmainicon: require("../assets/imageset/hongmain.png"),
  house: require("../assets/imageset/house.png"),
  housesmall: require("../assets/imageset/housesmall.png"),
  housegray: require("../assets/imageset/housegray.png"),
  housegraysmall: require("../assets/imageset/housegraysmall.png"),
  business: require("../assets/imageset/business.png"),
  businesssmall: require("../assets/imageset/businesssmall.png"),
  businessgray: require("../assets/imageset/businessgray.png"),
  businessgraysmall: require("../assets/imageset/businessgraysmall.png"),
  move: require("../assets/imageset/move.png"),
  movesmall: require("../assets/imageset/movesmall.png"),
  movegray: require("../assets/imageset/movegray.png"),
  movegraysmall: require("../assets/imageset/movegraysmall.png"),
  cook: require("../assets/imageset/cook.png"),
  cooksmall: require("../assets/imageset/cooksmall.png"),
  cookgray: require("../assets/imageset/cookgray.png"),
  cookgraysmall: require("../assets/imageset/cookgraysmall.png"),
  help:require("../assets/imageset/help.png"),
  helpsmall:require("../assets/imageset/helpsmall.png"),
  helpgray:require("../assets/imageset/helpgray.png"),
  helpgraysmall:require("../assets/imageset/helpgraysmall.png"),
  gooutschool: require("../assets/imageset/gooutschool.png"),
  gooutschoolsmall: require("../assets/imageset/gooutschoolsmall.png"),
  gooutschoolgray: require("../assets/imageset/gooutschoolgray.png"),
  gooutschoolgraysmall: require("../assets/imageset/gooutschoolgraysmall.png"),
  hospital: require("../assets/imageset/hospital.png"),
  hospitalsmall: require("../assets/imageset/hospitalsmall.png"),
  hospitalgray: require("../assets/imageset/hospitalgray.png"),
  hospitalgraysmall: require("../assets/imageset/hospitalgraysmall.png"),
  babycare: require("../assets/imageset/babycare.png"),
  babycaresmall: require("../assets/imageset/babycaresmall.png"),
  babycaregray: require("../assets/imageset/babycaregray.png"),
  babycaregraysmall: require("../assets/imageset/babycaregraysmall.png"),
  carry: require("../assets/imageset/carry.png"),
  carrysmall: require("../assets/imageset/carrysmall.png"),
  carrygray: require("../assets/imageset/carrygray.png"),
  carrygraysmall: require("../assets/imageset/carrygraysmall.png"),
  patientcare : require("../assets/imageset/patientcare.png"),
  patientcaresmall : require("../assets/imageset/patientcaresmall.png"),
  patientcaregray : require("../assets/imageset/patientcaregray.png"),
  patientcaregraysmall : require("../assets/imageset/patientcaregraysmall.png"),
  recipe: require("../assets/imageset/recipe.png"),
  recipesmall: require("../assets/imageset/recipesmall.png"),
  recipegray: require("../assets/imageset/recipegray.png"),
  recipegraysmall: require("../assets/imageset/recipegraysmall.png"),
  schoolevent : require("../assets/imageset/schoolevent.png"),
  schooleventsmall : require("../assets/imageset/schooleventsmall.png"),
  schooleventgray : require("../assets/imageset/schooleventgray.png"),
  schooleventgraysmall : require("../assets/imageset/schooleventgraysmall.png"),
  dog : require("../assets/imageset/dog.png"),
  dogsmall : require("../assets/imageset/dogsmall.png"),
  doggray : require("../assets/imageset/doggray.png"),
  doggraysmall : require("../assets/imageset/doggraysmall.png"),
  doghospital : require("../assets/imageset/doghospital.png"),
  doghospitalsmall : require("../assets/imageset/doghospitalsmall.png"),
  doghospitalgray : require("../assets/imageset/doghospitalgray.png"),
  doghospitalgraysmall : require("../assets/imageset/doghospitalgraysmall.png"),
  shopping : require("../assets/imageset/shopping.webp"),
  shoppingsmall : require("../assets/imageset/shoppingsmall.png"),
  shoppinggray : require("../assets/imageset/shoppinggray.png"),
  shoppinggraysmall : require("../assets/imageset/shoppinggraysmall.png"),
  lesson : require("../assets/imageset/lesson.png"),
  lessonsmall : require("../assets/imageset/lessonsmall.png"),
  lessongray : require("../assets/imageset/lessongray.png"),
  lessongraysmall : require("../assets/imageset/lessongraysmall.png"),
  map8: require("../assets/imageset/map8.png"),
  map9: require("../assets/imageset/map9.png"),
  eyesolid : require("../assets/imageset/eye-solid.png"),
  rulletstart : require("../assets/imageset/rulletstart.png"),
  rulletstop : require("../assets/imageset/rulletstop.png"),
  rulletpin : require("../assets/imageset/rulletpin.png"),
  freeze : require("../assets/imageset/freeze.png"),
  map : require("../assets/imageset/map.png"),
  mappin : require("../assets/imageset/map-pin.png"),
  distance : require("../assets/imageset/distance.png"),
  tourfestival : require("../assets/imageset/tourfestival.png"),
  gps_e :require("../assets/imageset/gps_e.png"),
  gps_d :require("../assets/imageset/gps_d.png"),
  home_e :require("../assets/imageset/home_e.png"),
  home_d :require("../assets/imageset/home_d.png"),
  room_e :require("../assets/imageset/room_e.png"),
  room_d :require("../assets/imageset/room_d.png"),
  community_e :require("../assets/imageset/community_e.png"),
  community_d :require("../assets/imageset/community_d.png"),
  myinfo_e :require("../assets/imageset/myinfo_e.png"),
  myinfo_d :require("../assets/imageset/myinfo_d.png"),
  medal : require("../assets/imageset/medal.png"),
  honggroup : require("../assets/imageset/honggroup.webp"),
  roomgroup : require("../assets/imageset/roommain.webp"),
  worker : require("../assets/imageset/hongbestlady.webp"),
  homescreen : require("../assets/imageset/splashscreen.png"),
  roombanner : require("../assets/imageset/roombanner.webp"),
  community : require("../assets/imageset/community.png"),
  searchgif : require("../assets/imageset/search.gif"),
  ai : require("../assets/imageset/ai.webp"),
  aigif : require("../assets/imageset/ai.gif"),
  event : require("../assets/imageset/event.webp"),

  gate1 : require("../assets/imageset/img-spc01.png"),
  gate2 : require("../assets/imageset/img-spc02.png"),
  gate3 : require("../assets/imageset/img-spc03.png"),
  matching: require("../assets/imageset/matching.gif"),
  matching2: require("../assets/imageset/matching2.png"),
  chat1 : require("../assets/imageset/chat1.png"),
  chat2 : require("../assets/imageset/chat2.png"),
  chat3 : require("../assets/imageset/chat3.png"),
  chat4 : require("../assets/imageset/chat4.png"),
  chat5 : require("../assets/imageset/chat5.png"),
  contact : require("../assets/imageset/contact.png"),
  request1 : require("../assets/imageset/request.gif"),
  request2: require("../assets/imageset/request.png"),
  radioenable : require("../assets/imageset/radioenable.png"),
  radiodisable : require("../assets/imageset/radiodisable.png"),
  teacher : require("../assets/imageset/teacher.png"),
  rullet : require("../assets/imageset/rullet.gif"),
  challenge : require("../assets/imageset/challenge.gif"),
  attendance : require("../assets/imageset/attendance.png"),
  game1 : require("../assets/imageset/game1.png"),
  game2 : require("../assets/imageset/game2.png"),
  game3 : require("../assets/imageset/game3.png"),
  game4 : require("../assets/imageset/game4.png"),
  game5 : require("../assets/imageset/game5.png"),
  game6 : require("../assets/imageset/game6.png"),
  game7 : require("../assets/imageset/game7.png"),
  game8 : require("../assets/imageset/game8.png"),
  game9 : require("../assets/imageset/game9.png"),
  game10 : require("../assets/imageset/game10.png"),
  game11 : require("../assets/imageset/game11.png"),
  game12 : require("../assets/imageset/game12.png"),
  game13 : require("../assets/imageset/game13.png"),
  game14 : require("../assets/imageset/game14.png"),
  game15 : require("../assets/imageset/game15.png"),
  game16 : require("../assets/imageset/game16.png"),
  game17 : require("../assets/imageset/game17.png"),
  game18 : require("../assets/imageset/game18.png"),
  game19 : require("../assets/imageset/game19.png"),
  game20 : require("../assets/imageset/game20.png"),
  gamestart : require("../assets/imageset/gamestart.gif"),


};

export const IsWork = (category) =>{

  if(category == WORKNAME.HOMECLEAN){
    return true;
  }else if(category == WORKNAME.BUSINESSCLEAN){
    return true;
  }else if(category == WORKNAME.MOVECLEAN){
    return true;
  }else if(category == WORKNAME.FOODPREPARE){
    return true;
  }else if(category == WORKNAME.GOOUTSCHOOL){
    return true;
  }else if(category == WORKNAME.BABYCARE){
    return true;
  }else if(category == WORKNAME.ERRAND){
    return true;
  }else if(category == WORKNAME.PATIENTCARE){
    return true;
  }else if(category == WORKNAME.CARRYLOAD){
    return true;
  }else if(category == WORKNAME.GOHOSPITAL){
    return true;
  }else if(category == WORKNAME.RECIPETRANSMIT){
    return true;
  }else if(category == WORKNAME.GOSCHOOLEVENT){
    return true;
  }else if(category == WORKNAME.SHOPPING){
    return true;
  }else if(category == WORKNAME.GODOGHOSPITAL){
    return true;
  }else if(category == WORKNAME.GODOGWALK){
    return true;
  }else if(category == WORKNAME.LESSON){
    return true;
  }else{
    return false;
  }
}

export const Seekimage = (category) =>{
  if(category == WORKNAME.ALLWORK){
    return imageDB.pclogo;
  }
  if(category == WORKNAME.HOMECLEAN){
    return imageDB.house;
  }else if(category == WORKNAME.BUSINESSCLEAN){
    return imageDB.business;
  }else if(category == WORKNAME.MOVECLEAN){
    return imageDB.move;
  }else if(category == WORKNAME.FOODPREPARE){
    return imageDB.cook;
  }else if(category == WORKNAME.GOOUTSCHOOL){
    return imageDB.gooutschool;
  }else if(category == WORKNAME.BABYCARE){
    return imageDB.babycare;
  }else if(category == WORKNAME.ERRAND){
    return imageDB.help;
  }else if(category == WORKNAME.PATIENTCARE){
    return imageDB.patientcare;
  }else if(category == WORKNAME.CARRYLOAD){
    return imageDB.carry;
  }else if(category == WORKNAME.GOHOSPITAL){
    return imageDB.hospital;
  }else if(category == WORKNAME.RECIPETRANSMIT){
    return imageDB.recipe;
  }else if(category == WORKNAME.GOSCHOOLEVENT){
    return imageDB.schoolevent;
  }else if(category == WORKNAME.SHOPPING){
    return imageDB.shopping;
  }else if(category == WORKNAME.GODOGHOSPITAL){
    return imageDB.doghospital;
  }else if(category == WORKNAME.GODOGWALK){
    return imageDB.dog;
  }else if(category == WORKNAME.LESSON){
    return imageDB.lesson;
  }else{
    return imageDB.roomsize1;
  }
}
export const Seekgrayimage = (category) =>{
  if(category == WORKNAME.ALLWORK){
    return imageDB.pclogo;
  }
  if(category == WORKNAME.HOMECLEAN){
    return imageDB.housegray;
  }else if(category == WORKNAME.BUSINESSCLEAN){
    return imageDB.businessgray;
  }else if(category == WORKNAME.MOVECLEAN){
    return imageDB.movegray;
  }else if(category == WORKNAME.FOODPREPARE){
    return imageDB.cookgray;
  }else if(category == WORKNAME.GOOUTSCHOOL){
    return imageDB.gooutschoolgray;
  }else if(category == WORKNAME.BABYCARE){
    return imageDB.babycaregray;
  }else if(category == WORKNAME.ERRAND){
    return imageDB.helpgray;
  }else if(category == WORKNAME.PATIENTCARE){
    return imageDB.patientcaregray;
  }else if(category == WORKNAME.CARRYLOAD){
    return imageDB.carrygray;
  }else if(category == WORKNAME.GOHOSPITAL){
    return imageDB.hospitalgray;
  }else if(category == WORKNAME.RECIPETRANSMIT){
    return imageDB.recipegray;
  }else if(category == WORKNAME.GOSCHOOLEVENT){
    return imageDB.schooleventgray;
  }else if(category == WORKNAME.SHOPPING){
    return imageDB.shoppinggray;
  }else if(category == WORKNAME.GODOGHOSPITAL){
    return imageDB.doghospitalgray;
  }else if(category == WORKNAME.GODOGWALK){
    return imageDB.doggray;
  }else if(category == WORKNAME.LESSON){
    return imageDB.lessongray;
  }else{
    return imageDB.roomsize1;
  }
   
}


